function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        let cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

 export function updateCartView(countSelector, priceSelector, data) {
    const countElement = document.querySelector(countSelector);
    if (countElement) {
        countElement.textContent = data.total_items_count;
    }

    const priceElement = document.querySelector(priceSelector);
    if (priceElement) {
        priceElement.textContent = `${Math.floor(data.total_items_price)} HUF`
    }
}

function checkCart(count_selector, price_selector) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', addToCardUrl);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.onload = function () {
        if (xhr.status === 200) {
            var resp = JSON.parse(xhr.responseText);
            updateCartView(count_selector, price_selector, resp);
        } else {
            alert('Request failed. Returned status of ' + xhr.status);
        }
    };
    xhr.send();
}

export function addToCart(article_id, count) {
    return fetch(addToCardUrl, {
        method: 'POST', headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken')
        }, body: JSON.stringify({
            'article_id': article_id, 'count': count
        })
    });
}

function removeFromCart(item_id, count_selector, price_selector) {
    return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();

        xhr.open('POST', removeFromCardUrl, true);
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onload = function () {
            if (xhr.status === 200) {
                try {
                    var resp = JSON.parse(xhr.responseText);
                    updateCartView(count_selector, price_selector, resp);
                    resolve(resp); // Успешный результат
                } catch (error) {
                    reject('Failed to parse server response.');
                }
            } else {
                reject(`Request failed. Status: ${xhr.status}`);
            }
        };

        xhr.onerror = function () {
            reject('Network error occurred.');
        };

        const requestData = {item_id: item_id};
        xhr.send(JSON.stringify(requestData));
    });
}

export function initCart(count_selector, price_selector) {
    window.addEventListener('DOMContentLoaded', checkCart(count_selector, price_selector));
}

// Показує лоадер
function showLoader() {
    const loader = document.querySelector('#loader');
    if (loader) loader.style.display = 'block';
}

// Ховає лоадер
function hideLoader() {
    const loader = document.querySelector('#loader');
    if (loader) loader.style.display = 'none';
}

// Оновлює стан кнопки `decrement`
function updateDecrementButtonState(quantityInput) {
    const decrementButton = quantityInput
        .closest('.cart__table_quantity')
        .querySelector('.decrement');
    const currentCount = parseInt(quantityInput.value, 10);

    if (currentCount <= 1) {
        decrementButton.setAttribute('disabled', 'disabled');
    } else {
        decrementButton.removeAttribute('disabled');
    }
}

// Ініціалізує обробники подій для всіх інпутів у контейнері
function initializeInputHandlers(cartContainer) {
    cartContainer.querySelectorAll('input[type="number"]').forEach(input => {
        let previousValue = parseInt(input.value, 10) || 1;

        // Ігнорування введення вручну
        input.addEventListener('input', function () {
            const newValue = parseInt(input.value, 10);
            if (isNaN(newValue) || newValue < 1) {
                input.value = previousValue;
            } else if (newValue !== previousValue) {
                input.value = previousValue; // Ігноруємо зміни
            }
            // Оновлюємо стан кнопки `decrement`
            updateDecrementButtonState(input);
        });

        // Відновлення значення після втрати фокуса
        input.addEventListener('blur', function () {
            input.value = previousValue;
            updateDecrementButtonState(input); // Оновлюємо стан кнопки
        });
    });
}

function updateCartContent(data, cartContainer) {
    cartContainer.innerHTML = data.cart_items_html;

    const totalPriceElement = document.querySelector('#total_price');
    if (totalPriceElement) {
        totalPriceElement.textContent = `${Math.floor(data.total_items_price)} HUF`
    }

    initializeInputHandlers(cartContainer);
}

export function initializeCartEvents(cartContainer, updateCardUrl) {
    if (!cartContainer) {
        return;
    }

    cartContainer.addEventListener('click', function (event) {
        const target = event.target;

        // Удаление товара через спан
        if (target.classList.contains('cart__table_remove')) {
            const articleId = target.dataset.itemId;

            if (!articleId) {
                return;
            }

            removeFromCart(articleId, '#total_items_count', '#total_price')
                .then((data) => {
                    return fetch(updateCardUrl, {
                        method: 'POST',
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Content-Type': 'application/json',
                            'X-CSRFToken': getCookie('csrftoken'),
                        },
                        body: JSON.stringify({}),
                    });
                })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error during cart update! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    updateCartContent(data, cartContainer);
                })
                .catch((error) => {
                    alert('Не удалось удалить товар или обновить корзину. Попробуйте позже.');
                });
        }

        // Увеличение или уменьшение количества
        if (target.classList.contains('increment') || target.classList.contains('decrement')) {
            const articleId = target.dataset.articleId;
            const change = target.classList.contains('increment') ? 1 : -1;

            const quantityInput = target
                .closest('.cart__table_quantity')
                .querySelector('input[type="number"]');
            const currentCount = parseInt(quantityInput.value, 10);

            if (currentCount === 1 && change === -1) {
                return;
            }

            showLoader();

            addToCart(articleId, change)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(() => {
                    return fetch(updateCardUrl, {
                        method: 'POST',
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Content-Type': 'application/json',
                            'X-CSRFToken': getCookie('csrftoken')
                        },
                        body: JSON.stringify({})
                    });
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error during cart update! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    updateCartContent(data, cartContainer);
                })
                .catch(error => {
                    alert('Failed to update your cart. Please try again later.');
                })
                .finally(() => {
                    hideLoader();
                });
        }
    });

    initializeInputHandlers(cartContainer);
}